















































import store from "@/store";
import VesselsModule from "@/store/clients/Vessels.module";
import { ExtendedVessel } from "@/types/Vessel";
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
//  components
import StatusCard from "@/components/dataQuality/speedLog/StatusCard.vue";
import SensorQualityChart from "@/components/dataQuality/speedLog/Charts/SensorQualityChart.vue";
import SpeedComparisonChart from "@/components/dataQuality/speedLog/Charts/SpeedComparisonChart.vue";
import SpeedLogAvailabilityStatus from "@/components/dataQuality/speedLog/SpeedLogAvailabilityStatus.vue";
import SpeedLogTagHistory from "@/components/dataQuality/speedLog/SpeedLogTagHistory.vue";

const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    SpeedLogAvailabilityStatus,
    SpeedLogTagHistory,
    StatusCard,
    SensorQualityChart,
    SpeedComparisonChart,
  },
})
export default class SpeedLog extends Vue {
  useDerivedSTW = false;
  selectedVessel = "";
  selectedTimespan = "Last hour";
  availability = 97;
  outliers = 2;
  available = false;

  //  @Getters
  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get isEnvDevelopment(): boolean {
    return process.env.NODE_ENV === "development";
  }

  get isVesselSelected(): boolean {
    return this.selectedVessel !== "" && this.selectedVessel !== null;
  }
}
