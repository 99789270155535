export default {
  name: "Deviation from baseline",
  type: "line",
  color: "#17b26a",
  zIndex: 1,
  enableMouseTracking: true,
  cropThreshold: 9999,
  marker: {
    symbol: "circle",
  },
  tooltip: {
    outside: true,
    useHTML: true,
    enabled: true,
    borderWidth: 0,
    shadow: false,
    headerFormat: "<small>{point.key}</small><br>",
    pointFormat: "{point.y}",
    valueDecimals: 1,
    valueSuffix: " %",
    xDateFormat: "%d. %b, %Y",
    backgroundColor: "#000",
    style: {
      fontSize: "12px",
      color: "#fff",
    },
  },
  zones: [
    {
      value: 0.43,
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, "#17b26a"],
          [0.1, "#24b369"],
          [0.2, "#4cb86a"],
          [0.3, "#67bb6a"],
          [0.4, "#91bf6a"],
          [0.5, "#b8c369"],
          [0.6, "#d9c76a"],
          [0.7, "#f8ca6a"],
          [0.8, "#f9b562"],
          [0.9, "#e26343"],
          [1, "#db4b39"],
        ]
      }
    }
  ],
  data: [
    [
      1455494400000,
      -9.344745561661686
    ],
    [
      1455580800000,
      -10.806432457153
    ],
    [
      1455667200000,
      -6.345631902573403
    ],
    [
      1455753600000,
      -7.4088132220925305
    ],
    [
      1455840000000,
      -10.450785103119644
    ],
    [
      1455926400000,
      -10.44780655007427
    ],
    [
      1456012800000,
      -5.784773628691348
    ],
    [
      1456099200000,
      -2.5472377355310183
    ],
    [
      1456185600000,
      0.4277109677425129
    ],
    [
      1456272000000,
      -0.36880469426775025
    ],
    [
      1456531200000,
      -6.952996920482461
    ],
    [
      1457913600000,
      -9.071818544580665
    ],
    [
      1458000000000,
      -2.9027135432886286
    ],
    [
      1458086400000,
      -2.9330776233042637
    ],
    [
      1458172800000,
      -5.053687450582825
    ],
    [
      1458259200000,
      -4.110605097319287
    ],
    [
      1458345600000,
      -5.883150319358497
    ],
    [
      1458432000000,
      -3.685520718196521
    ],
    [
      1458518400000,
      -3.8606494401726823
    ],
    [
      1458604800000,
      -2.6286939232402182
    ],
    [
      1458691200000,
      -2.8897960445708857
    ],
    [
      1458777600000,
      -4.049838880700728
    ],
    [
      1458864000000,
      -8.105393912302368
    ],
    [
      1459814400000,
      -6.1257317918211545
    ],
    [
      1459900800000,
      -8.447882040273024
    ],
    [
      1459987200000,
      -3.1397213649082185
    ],
    [
      1460073600000,
      -6.551973537006867
    ],
    [
      1460160000000,
      -9.786614768902007
    ],
    [
      1460246400000,
      -10.601895654546352
    ],
    [
      1460332800000,
      -11.51342206309504
    ],
    [
      1460419200000,
      -9.857879466870166
    ],
    [
      1460505600000,
      -5.8509829587774
    ],
    [
      1460592000000,
      -5.1364041708015336
    ],
    [
      1460678400000,
      -5.5288488912398455
    ],
    [
      1460764800000,
      -10.343683108078624
    ],
    [
      1460851200000,
      -10.642425461171365
    ],
    [
      1460937600000,
      -6.646135408118977
    ],
    [
      1461628800000,
      -5.049353611201543
    ],
    [
      1461715200000,
      -3.3344548771354217
    ],
    [
      1461801600000,
      -2.9365567350691166
    ],
    [
      1461888000000,
      -4.188483728972044
    ],
    [
      1461974400000,
      -2.943309525529265
    ],
    [
      1462060800000,
      -2.7985220237167123
    ],
    [
      1462147200000,
      -1.8922220050722134
    ],
    [
      1462233600000,
      -1.6888381385457023
    ],
    [
      1462320000000,
      -2.905398993083909
    ],
    [
      1462406400000,
      -5.736819500025135
    ],
    [
      1462752000000,
      -4.153165851940715
    ],
    [
      1462838400000,
      -2.7149481416292787
    ],
    [
      1462924800000,
      -6.521396397968215
    ],
    [
      1463011200000,
      -2.4267471469592032
    ],
    [
      1463097600000,
      -6.495770804468055
    ],
    [
      1463184000000,
      -3.510251051341882
    ],
    [
      1463270400000,
      -5.831034365770505
    ],
    [
      1463356800000,
      -3.9012873251156943
    ],
    [
      1463443200000,
      -5.216502593920106
    ],
    [
      1463529600000,
      -3.6257128157812324
    ],
    [
      1463616000000,
      -7.219357189689876
    ],
    [
      1463702400000,
      -7.468294445344946
    ],
    [
      1463875200000,
      -6.864673316214618
    ],
    [
      1463961600000,
      -3.5993589599437272
    ],
    [
      1464048000000,
      -2.975294918101188
    ],
    [
      1464134400000,
      -3.6646856130830847
    ],
    [
      1464220800000,
      -1.754650294323451
    ],
    [
      1464307200000,
      -0.5792426488119051
    ],
    [
      1464393600000,
      -1.4179248596742946
    ],
    [
      1464480000000,
      -1.1861817881561652
    ],
    [
      1464566400000,
      -2.567753565308937
    ],
    [
      1464652800000,
      -4.772027392950142
    ],
    [
      1464739200000,
      -6.596923397738823
    ],
    [
      1464825600000,
      -6.334050016901537
    ],
    [
      1464912000000,
      -4.343746252259513
    ],
    [
      1464998400000,
      -4.7672204377736636
    ],
    [
      1465084800000,
      -0.7968106521651398
    ],
    [
      1465171200000,
      -1.9242658074223356
    ],
    [
      1465257600000,
      -2.4835792647150003
    ],
    [
      1465344000000,
      -4.591446936835708
    ],
    [
      1465430400000,
      -2.9408452318588805
    ],
    [
      1465516800000,
      -1.1483718178222329
    ],
    [
      1465603200000,
      -3.6011786229032694
    ],
    [
      1465689600000,
      -8.051163803966046
    ],
    [
      1465776000000,
      -12.842825445770384
    ],
    [
      1465862400000,
      -9.679192241098892
    ],
    [
      1465948800000,
      -6.994850965462649
    ],
    [
      1466035200000,
      -5.401665723969198
    ],
    [
      1466121600000,
      -4.119027283607472
    ],
    [
      1466208000000,
      -2.2819634222283054
    ],
    [
      1466294400000,
      -1.2655671327037035
    ],
    [
      1466380800000,
      -1.9429712358465985
    ],
    [
      1466467200000,
      -2.7064314785461208
    ],
    [
      1466553600000,
      -2.359526620172071
    ],
    [
      1466640000000,
      -4.386066417301778
    ],
    [
      1466726400000,
      -5.407895208602157
    ],
    [
      1466812800000,
      -10.78522637975716
    ],
    [
      1467676800000,
      -7.847191999583162
    ],
    [
      1467763200000,
      -2.8246248107676455
    ],
    [
      1467849600000,
      -7.154117891533879
    ],
    [
      1467936000000,
      -5.564050096454294
    ],
    [
      1468022400000,
      -4.785488285437712
    ],
    [
      1468108800000,
      -3.9138876189235816
    ],
    [
      1468195200000,
      -2.715385720166456
    ],
    [
      1468281600000,
      -5.855595458192538
    ],
    [
      1468368000000,
      -3.721383201929626
    ],
    [
      1468454400000,
      -9.338401033209239
    ],
    [
      1468540800000,
      -5.514655356326095
    ],
    [
      1468627200000,
      -1.8316888822931898
    ],
    [
      1468713600000,
      -3.530430018508479
    ],
    [
      1468800000000,
      -10.209014982434269
    ],
    [
      1469059200000,
      -5.009862048664755
    ],
    [
      1469145600000,
      -3.634843199572846
    ],
    [
      1469232000000,
      -2.271384772021068
    ],
    [
      1469318400000,
      -1.8195587014578996
    ],
    [
      1469404800000,
      -1.33571304459803
    ],
    [
      1469491200000,
      -0.6311672687109048
    ],
    [
      1469577600000,
      -0.5038716046496315
    ],
    [
      1469664000000,
      -1.5263264255970834
    ],
    [
      1469750400000,
      -1.864738434646928
    ],
    [
      1469836800000,
      -1.6575136441874592
    ],
    [
      1469923200000,
      -0.9426481852274982
    ],
    [
      1470441600000,
      -6.552626254296198
    ],
    [
      1470528000000,
      -6.266166500501954
    ],
    [
      1470614400000,
      -3.436484066792227
    ],
    [
      1470700800000,
      -1.8022334819595607
    ],
    [
      1470787200000,
      -7.175324911898069
    ],
    [
      1470873600000,
      -10.739897990429418
    ],
    [
      1470960000000,
      -9.622132412751881
    ],
    [
      1471046400000,
      -7.622069667809973
    ],
    [
      1471132800000,
      -5.299450350303499
    ],
    [
      1471478400000,
      -4.468494257560579
    ],
    [
      1471564800000,
      -5.6268554201779155
    ],
    [
      1471651200000,
      -3.0261038572329646
    ],
    [
      1471737600000,
      -2.395185598570638
    ],
    [
      1471824000000,
      -3.4422921509629076
    ],
    [
      1471910400000,
      -4.714805626253851
    ],
    [
      1471996800000,
      -4.374790835661937
    ],
    [
      1472083200000,
      -5.228754810418712
    ],
    [
      1472169600000,
      -10.207515238442594
    ],
    [
      1472256000000,
      -6.818053353992254
    ],
    [
      1472342400000,
      -8.426781035700683
    ],
    [
      1472428800000,
      -5.557942677137012
    ],
    [
      1472515200000,
      -5.422657966015035
    ],
    [
      1472601600000,
      -5.756170677821845
    ],
    [
      1472688000000,
      -8.613504368192414
    ],
    [
      1472774400000,
      -9.797070089686935
    ],
    [
      1472860800000,
      -6.092304363771163
    ],
    [
      1472947200000,
      -5.992359450879739
    ],
    [
      1473033600000,
      -3.608267362783043
    ],
    [
      1473120000000,
      -3.3718054058200373
    ],
    [
      1474243200000,
      -2.7295021513724596
    ],
    [
      1474329600000,
      -3.31655436140456
    ],
    [
      1475020800000,
      -8.179301398655088
    ],
    [
      1475107200000,
      -4.674261820437316
    ],
    [
      1475193600000,
      -7.172459383073476
    ],
    [
      1475280000000,
      -12.131382086799462
    ],
    [
      1475366400000,
      -7.682767719734809
    ],
    [
      1475452800000,
      -1.0773551549164846
    ],
    [
      1475539200000,
      -0.9001825241356337
    ],
    [
      1475625600000,
      -3.3748765481174714
    ],
    [
      1475712000000,
      -9.189776017136685
    ],
    [
      1475798400000,
      -11.059518789099863
    ],
    [
      1475884800000,
      -4.240669530252485
    ],
    [
      1476057600000,
      -4.052832422511619
    ],
    [
      1476144000000,
      -2.6742661540063035
    ],
    [
      1476230400000,
      -2.0495080617820913
    ],
    [
      1476316800000,
      -0.8189505171126409
    ],
    [
      1476403200000,
      -2.057688295614138
    ],
    [
      1476489600000,
      -3.033984324342321
    ],
    [
      1476576000000,
      -6.60340143442335
    ],
    [
      1476662400000,
      -5.226563889850008
    ],
    [
      1476748800000,
      -5.369758089244538
    ],
    [
      1476835200000,
      -3.1477766429966363
    ],
    [
      1476921600000,
      -3.2680463809822955
    ],
    [
      1477180800000,
      1.2780079955826087
    ],
    [
      1477267200000,
      -5.98374656417605
    ],
    [
      1477353600000,
      -5.1587011938467064
    ],
    [
      1477440000000,
      -9.692518322199307
    ],
    [
      1477526400000,
      -6.647049961488268
    ],
    [
      1477612800000,
      -6.817338984485078
    ],
    [
      1478908800000,
      -7.502385104367259
    ],
    [
      1478995200000,
      -5.601424402534952
    ],
    [
      1479081600000,
      -5.439795068258369
    ],
    [
      1479168000000,
      -6.301624298170908
    ],
    [
      1479254400000,
      -4.9393812310221294
    ],
    [
      1479340800000,
      -4.0368671330278
    ],
    [
      1479427200000,
      -4.305445468440877
    ],
    [
      1479513600000,
      -5.144239674950536
    ],
    [
      1479600000000,
      -6.071941501179186
    ],
    [
      1479686400000,
      -4.3705962568365
    ],
    [
      1479772800000,
      -3.125847295520567
    ],
    [
      1479859200000,
      -9.61550250369544
    ],
    [
      1479945600000,
      -15.45906630701532
    ],
    [
      1481155200000,
      -7.002546743345266
    ],
    [
      1481760000000,
      -13.266246434241864
    ],
    [
      1481846400000,
      -15.192713658987374
    ],
    [
      1481932800000,
      -17.211481280193446
    ],
    [
      1482019200000,
      -15.064601257069938
    ],
    [
      1482105600000,
      -14.199775162432429
    ],
    [
      1482192000000,
      -13.731995655230397
    ],
    [
      1482278400000,
      -10.292521214879615
    ],
    [
      1482364800000,
      -5.307412481626106
    ],
    [
      1482451200000,
      -3.9517969549206042
    ],
    [
      1482537600000,
      -2.785537252838318
    ],
    [
      1482710400000,
      -3.6458014918694803
    ],
    [
      1482796800000,
      -6.65707865708518
    ],
    [
      1482883200000,
      -11.297300110368631
    ],
    [
      1483056000000,
      -5.9384955888675055
    ],
    [
      1483142400000,
      -4.089846888053421
    ],
    [
      1483228800000,
      -3.160623134881409
    ],
    [
      1483315200000,
      -4.4268740987716
    ],
    [
      1483401600000,
      -5.5703232887847856
    ],
    [
      1483488000000,
      -7.648533010711467
    ],
    [
      1483574400000,
      -7.610522150912233
    ],
    [
      1483660800000,
      -5.741351810599458
    ],
    [
      1483747200000,
      -5.478417261746255
    ],
    [
      1483833600000,
      -13.887848539944152
    ],
    [
      1483920000000,
      -17.128606707287737
    ],
    [
      1484092800000,
      -8.733868661836235
    ],
    [
      1484611200000,
      -5.388259319322245
    ],
    [
      1484697600000,
      -1.7071862930795807
    ],
    [
      1484784000000,
      -7.5159088024386875
    ],
    [
      1484870400000,
      -1.9830556459058253
    ],
    [
      1484956800000,
      -3.5767297710392643
    ],
    [
      1485043200000,
      -10.04719163551727
    ],
    [
      1485129600000,
      -11.337709687765667
    ],
    [
      1485216000000,
      -11.220595842556436
    ],
    [
      1485302400000,
      -6.116809234640857
    ],
    [
      1485475200000,
      -3.8749494887038005
    ],
    [
      1485561600000,
      -5.059335269013477
    ],
    [
      1485648000000,
      -6.517250092493729
    ],
    [
      1485907200000,
      -5.600261335870277
    ],
    [
      1485993600000,
      -4.728072521304266
    ],
    [
      1486080000000,
      -4.637809320885411
    ],
    [
      1486166400000,
      -7.581820217065505
    ],
    [
      1486252800000,
      -4.82994346383886
    ],
    [
      1486339200000,
      -4.569385487235639
    ],
    [
      1486425600000,
      -6.158111268199548
    ],
    [
      1486512000000,
      -5.061973351815983
    ],
    [
      1486598400000,
      -10.9279995499526
    ],
    [
      1486684800000,
      -16.357303759118633
    ],
    [
      1486771200000,
      -12.194243432905091
    ],
    [
      1486857600000,
      -7.829197497165601
    ],
    [
      1486944000000,
      -5.678006204962295
    ],
    [
      1487030400000,
      -1.5404202669740876
    ],
    [
      1487116800000,
      -1.7352370017446612
    ],
    [
      1487289600000,
      -12.966361963482417
    ],
    [
      1487376000000,
      -10.582435439715864
    ],
    [
      1487462400000,
      -10.392374068169197
    ],
    [
      1487548800000,
      -7.349209974442064
    ],
    [
      1487635200000,
      -4.166363618869053
    ],
    [
      1487721600000,
      0.01933139813124604
    ],
    [
      1488499200000,
      -5.011742245951879
    ],
    [
      1488585600000,
      -3.6156155337274782
    ],
    [
      1488672000000,
      -4.294592521299493
    ],
    [
      1488758400000,
      -4.723762205637201
    ],
    [
      1488844800000,
      -4.077379865054512
    ],
    [
      1488931200000,
      -3.8750872646073105
    ],
    [
      1489017600000,
      -8.598706798621452
    ],
    [
      1489104000000,
      -6.083767652504058
    ],
    [
      1489190400000,
      -4.394609653486726
    ],
    [
      1489276800000,
      -6.577573247885101
    ],
    [
      1489363200000,
      -5.277934357752077
    ],
    [
      1489449600000,
      -7.094151722579481
    ],
    [
      1489536000000,
      -6.556211665234927
    ],
    [
      1489622400000,
      -6.477903564385321
    ],
    [
      1489708800000,
      -4.644648710501496
    ],
    [
      1489795200000,
      -6.736354241995005
    ],
    [
      1489881600000,
      -9.507258540091028
    ],
    [
      1489968000000,
      -9.765195651501845
    ],
    [
      1490054400000,
      -6.695222495092037
    ],
    [
      1490140800000,
      -6.28268769453437
    ],
    [
      1490227200000,
      -3.7218191307995436
    ],
    [
      1490572800000,
      -7.768782366512302
    ],
    [
      1490659200000,
      -5.207885817712856
    ],
    [
      1490832000000,
      -9.99918107447963
    ],
    [
      1490918400000,
      -3.7578245738827625
    ],
    [
      1491004800000,
      -3.6793617479404417
    ],
    [
      1491091200000,
      -6.287909242401042
    ],
    [
      1491177600000,
      -4.301372612406138
    ],
    [
      1491264000000,
      -5.488932739470084
    ],
    [
      1491350400000,
      -7.2088106186446685
    ],
    [
      1491436800000,
      -3.9434775537247497
    ],
    [
      1491523200000,
      -5.548964503948269
    ],
    [
      1491609600000,
      -7.17888564138432
    ],
    [
      1491955200000,
      -7.447717774847456
    ],
    [
      1492041600000,
      -6.317170599281757
    ],
    [
      1492128000000,
      -6.634453672491989
    ],
    [
      1492214400000,
      -7.9731214374164106
    ],
    [
      1492300800000,
      -9.748865292217728
    ],
    [
      1492387200000,
      -9.470588491546728
    ],
    [
      1492473600000,
      -10.135720685609865
    ],
    [
      1492560000000,
      -8.572723860337783
    ],
    [
      1492646400000,
      -6.087830647435801
    ],
    [
      1492732800000,
      -5.607102198213917
    ],
    [
      1492819200000,
      -7.761816890140733
    ],
    [
      1492905600000,
      -11.098760835630216
    ],
    [
      1492992000000,
      -9.936052808444265
    ],
    [
      1493078400000,
      -6.582440549928337
    ],
    [
      1493164800000,
      -1.6640020915188138
    ],
    [
      1493251200000,
      -6.811452443053362
    ],
    [
      1493337600000,
      -11.259411340817648
    ],
    [
      1493424000000,
      -10.33183799459103
    ],
    [
      1493510400000,
      -12.358497597197966
    ],
    [
      1493596800000,
      -15.183741753062051
    ],
    [
      1493683200000,
      -10.342342099142817
    ],
    [
      1493769600000,
      -7.243918283307454
    ],
    [
      1493856000000,
      -5.204601018082916
    ],
    [
      1493942400000,
      -3.8795985178730494
    ],
    [
      1494028800000,
      -4.631729349579591
    ],
    [
      1494115200000,
      -3.563192964051811
    ],
    [
      1494201600000,
      -3.302605504397048
    ],
    [
      1494288000000,
      -5.0752168980108
    ],
    [
      1494374400000,
      -6.765153821717368
    ],
    [
      1494460800000,
      -5.979470638849837
    ],
    [
      1494547200000,
      -5.277827800548753
    ],
    [
      1494633600000,
      -7.890732270357107
    ],
    [
      1494720000000,
      -12.188826878411305
    ],
    [
      1494806400000,
      -6.229409556346545
    ],
    [
      1494892800000,
      -2.200990053820195
    ],
    [
      1494979200000,
      -1.6421072249783066
    ],
    [
      1495065600000,
      -5.365009990827608
    ],
    [
      1495152000000,
      -4.958471792616987
    ],
    [
      1495238400000,
      -3.8219768574330075
    ],
    [
      1495324800000,
      -1.5295132984699755
    ],
    [
      1495411200000,
      -4.041526191150951
    ],
    [
      1495497600000,
      -6.528973801494281
    ],
    [
      1495584000000,
      -10.194521307188158
    ],
    [
      1495670400000,
      -8.790330001507506
    ],
    [
      1495756800000,
      -7.34683545899442
    ],
    [
      1495843200000,
      -7.056450716828742
    ],
    [
      1495929600000,
      -9.478416005175164
    ],
    [
      1496016000000,
      -14.304298169875581
    ],
    [
      1496102400000,
      -14.582850600025544
    ],
    [
      1496188800000,
      -10.095855773363708
    ],
    [
      1496275200000,
      -16.233221370017144
    ],
    [
      1496448000000,
      -15.894357495448133
    ],
    [
      1496534400000,
      -12.595765635578326
    ],
    [
      1496707200000,
      -4.244609947486105
    ],
    [
      1496793600000,
      -3.383735329422869
    ],
    [
      1496880000000,
      -3.0322252305044253
    ],
    [
      1496966400000,
      -3.9533235725705445
    ],
    [
      1497052800000,
      -3.0302071277313467
    ],
    [
      1497139200000,
      -2.639525424170858
    ],
    [
      1497225600000,
      -2.819746053904343
    ],
    [
      1497312000000,
      -3.893451310520399
    ],
    [
      1497398400000,
      -6.539416419148645
    ],
    [
      1497484800000,
      -6.054866897562676
    ],
    [
      1497571200000,
      -6.011296382771277
    ],
    [
      1497657600000,
      -3.8158231598805736
    ],
    [
      1497744000000,
      -1.165645949641423
    ],
    [
      1497830400000,
      -2.241942952690228
    ],
    [
      1497916800000,
      -2.686622998364881
    ],
    [
      1498003200000,
      -4.7110364388913295
    ],
    [
      1498089600000,
      -5.110329206183153
    ],
    [
      1498176000000,
      -4.819789577004477
    ],
    [
      1498262400000,
      -5.55348082239842
    ],
    [
      1498348800000,
      -5.594416047728596
    ],
    [
      1498435200000,
      -10.154221671444136
    ],
    [
      1498521600000,
      -11.796796486843315
    ],
    [
      1498608000000,
      -8.732283846002906
    ],
    [
      1498780800000,
      -4.814607172165358
    ],
    [
      1498867200000,
      -5.3440347801570125
    ],
    [
      1498953600000,
      -3.9890999778519673
    ],
    [
      1499040000000,
      -3.1616861678995174
    ],
    [
      1499126400000,
      -4.429722243340027
    ],
    [
      1499212800000,
      -3.7168639090901987
    ],
    [
      1499299200000,
      -3.6964777816208083
    ],
    [
      1499385600000,
      -2.7984824400066777
    ],
    [
      1499472000000,
      -5.463594905056052
    ],
    [
      1499558400000,
      -7.530945570386945
    ],
    [
      1499644800000,
      -9.913735608658769
    ],
    [
      1499731200000,
      -7.504981988655032
    ],
    [
      1499990400000,
      -6.187598088522888
    ],
    [
      1500076800000,
      -3.310481036311932
    ],
    [
      1500163200000,
      -3.7115814326247762
    ],
    [
      1500249600000,
      -5.047854305073465
    ],
    [
      1500336000000,
      -4.363739887316352
    ],
    [
      1500422400000,
      -4.147090474407833
    ],
    [
      1500508800000,
      -3.3534270767130754
    ],
    [
      1500595200000,
      -3.9529639922216115
    ],
    [
      1500681600000,
      -6.920205352820147
    ],
    [
      1500768000000,
      -7.363528686622385
    ],
    [
      1500854400000,
      -7.095954811712135
    ],
    [
      1500940800000,
      -6.799319770656079
    ],
    [
      1501027200000,
      -4.346912202124236
    ],
    [
      1501113600000,
      -4.359827538056886
    ],
    [
      1501200000000,
      -4.009709688609659
    ],
    [
      1501286400000,
      -4.513253387880307
    ],
    [
      1501372800000,
      -4.105966949191676
    ],
    [
      1501459200000,
      -6.91131293109938
    ],
    [
      1501545600000,
      -7.580853708785625
    ],
    [
      1501632000000,
      -5.8164046026183245
    ],
    [
      1501718400000,
      -5.024976043850766
    ],
    [
      1501804800000,
      -4.988356015621555
    ],
    [
      1501977600000,
      -4.779186697282196
    ],
    [
      1502064000000,
      -3.3333565563731926
    ],
    [
      1502150400000,
      -3.1253492446287354
    ],
    [
      1502236800000,
      -3.6557738234669412
    ],
    [
      1502323200000,
      -2.8383591471928216
    ],
    [
      1502409600000,
      -2.3619660397926854
    ],
    [
      1502496000000,
      -2.900184248824285
    ],
    [
      1502582400000,
      -5.513699262656014
    ],
    [
      1502668800000,
      -6.945229885030533
    ],
    [
      1502755200000,
      -6.391262392232412
    ],
    [
      1502841600000,
      -3.68942840389956
    ],
    [
      1503014400000,
      -4.6343217391062845
    ],
    [
      1503100800000,
      -3.846050485073059
    ],
    [
      1503187200000,
      -3.3039930836998916
    ],
    [
      1503273600000,
      -3.0559117010663717
    ],
    [
      1503360000000,
      -4.4614811195688775
    ],
    [
      1503446400000,
      -4.862203860289347
    ],
    [
      1503532800000,
      -3.774487762071789
    ],
    [
      1503619200000,
      -3.0728842071294653
    ],
    [
      1503705600000,
      -5.612464638069376
    ],
    [
      1503792000000,
      -7.230785605263928
    ],
    [
      1504051200000,
      -0.8495354872763472
    ],
    [
      1505001600000,
      -7.839848332979545
    ],
    [
      1505088000000,
      -6.197078982413124
    ],
    [
      1505174400000,
      -9.452749885500715
    ],
    [
      1505260800000,
      -9.046050081965321
    ],
    [
      1505347200000,
      -6.801391085271628
    ],
    [
      1505433600000,
      -2.534683566434288
    ],
    [
      1505520000000,
      -4.171627370993131
    ],
    [
      1505606400000,
      -6.861950376813387
    ],
    [
      1505692800000,
      -9.944314454365546
    ],
    [
      1506124800000,
      -6.446278073327661
    ],
    [
      1506211200000,
      -4.885669725247449
    ],
    [
      1506297600000,
      -4.448662012559675
    ],
    [
      1506384000000,
      -3.4693784554197413
    ],
    [
      1506470400000,
      -3.8563525009692445
    ],
    [
      1506556800000,
      -4.430143745696729
    ],
    [
      1506643200000,
      -3.872752019032248
    ],
    [
      1506729600000,
      -5.555548730324342
    ],
    [
      1506816000000,
      -6.221688755173449
    ],
    [
      1506902400000,
      -4.007664012394378
    ],
    [
      1507075200000,
      -14.552446037323291
    ],
    [
      1507248000000,
      -4.443099169589055
    ],
    [
      1507334400000,
      -5.14930797989176
    ],
    [
      1507420800000,
      -7.069151529024616
    ],
    [
      1507507200000,
      -3.9026744511914226
    ],
    [
      1507593600000,
      -5.749612659825801
    ],
    [
      1507680000000,
      -9.161857868139494
    ],
    [
      1507766400000,
      -8.408036123144921
    ],
    [
      1507852800000,
      -7.829390002519811
    ],
    [
      1507939200000,
      -5.427476885961705
    ],
    [
      1508025600000,
      -5.78655749832717
    ],
    [
      1508112000000,
      -4.4006930453672926
    ],
    [
      1508198400000,
      -5.830552154828435
    ],
    [
      1508284800000,
      -8.818169464394112
    ],
    [
      1508457600000,
      -7.449894902762029
    ],
    [
      1508544000000,
      -5.8205307272128195
    ],
    [
      1508630400000,
      -5.2125803385498966
    ],
    [
      1508716800000,
      -4.235211698127986
    ],
    [
      1508803200000,
      -5.311244934139748
    ],
    [
      1508889600000,
      -9.24769610789639
    ],
    [
      1508976000000,
      -8.892455683123382
    ],
    [
      1509062400000,
      -8.158562282814563
    ],
    [
      1509148800000,
      -8.608259296873184
    ],
    [
      1509235200000,
      -14.29120626307521
    ],
    [
      1509321600000,
      -14.795690244396587
    ],
    [
      1509408000000,
      -12.205683805043886
    ],
    [
      1509494400000,
      -5.7299405353000745
    ],
    [
      1509580800000,
      -4.4067615391182215
    ],
    [
      1509753600000,
      -5.904059616001285
    ],
    [
      1509840000000,
      -6.957378008204723
    ],
    [
      1509926400000,
      -8.883714481477158
    ],
    [
      1510012800000,
      -9.689015578274054
    ],
    [
      1510099200000,
      -8.635442215310492
    ],
    [
      1510185600000,
      -5.835614029821744
    ],
    [
      1510272000000,
      -5.79655377849699
    ],
    [
      1510358400000,
      -3.3544987296795714
    ],
    [
      1510444800000,
      -8.830705095160976
    ],
    [
      1510531200000,
      -16.124672205081744
    ],
    [
      1510617600000,
      -10.320604503040398
    ],
    [
      1510790400000,
      -5.8514826170284415
    ],
    [
      1510876800000,
      -5.599122684679915
    ],
    [
      1510963200000,
      -5.13696014391635
    ],
    [
      1511049600000,
      -6.829378540186181
    ],
    [
      1511136000000,
      -6.4567424035478425
    ],
    [
      1511222400000,
      -6.11662846147082
    ],
    [
      1511308800000,
      -8.196074642808858
    ],
    [
      1511395200000,
      -9.607705034123596
    ],
    [
      1511481600000,
      -13.867623054310807
    ],
    [
      1511568000000,
      -6.402469211622783
    ],
    [
      1511654400000,
      -7.696506915539974
    ],
    [
      1511827200000,
      -3.863300312860866
    ],
    [
      1511913600000,
      -9.66364323089065
    ],
    [
      1512000000000,
      -13.310455371501872
    ],
    [
      1512086400000,
      -12.936674922586542
    ],
    [
      1512172800000,
      -12.958786779391476
    ],
    [
      1512259200000,
      -15.664582251998139
    ],
    [
      1512345600000,
      -12.57588526649903
    ],
    [
      1512432000000,
      -9.245181673466375
    ],
    [
      1512518400000,
      -6.577200453434526
    ],
    [
      1512604800000,
      -6.670411644855442
    ],
    [
      1512691200000,
      -7.310781724826073
    ],
    [
      1512950400000,
      -11.141587867900967
    ],
    [
      1513123200000,
      -8.216344489002996
    ],
    [
      1513209600000,
      -5.37310014815385
    ],
    [
      1513296000000,
      -5.521313017674175
    ],
    [
      1513382400000,
      -6.86877130592202
    ],
    [
      1513468800000,
      -13.712444627484244
    ],
    [
      1513555200000,
      -8.004834788652387
    ],
    [
      1513641600000,
      -7.940808222842012
    ],
    [
      1513728000000,
      -8.846431852358721
    ],
    [
      1513814400000,
      -11.008410048945045
    ],
    [
      1513900800000,
      -7.482967935904817
    ],
    [
      1513987200000,
      -6.695515688964155
    ],
    [
      1514246400000,
      -4.856353449456618
    ],
    [
      1514332800000,
      -5.007711544084386
    ],
    [
      1514419200000,
      -6.487928831337157
    ],
    [
      1514505600000,
      -9.040886495081548
    ],
    [
      1514592000000,
      -10.601289069455571
    ],
    [
      1514678400000,
      -11.202396566745561
    ],
    [
      1514764800000,
      -11.443493571059369
    ],
    [
      1514851200000,
      -8.803057809120743
    ],
    [
      1514937600000,
      -6.375379184975997
    ],
    [
      1515024000000,
      -6.881974799652078
    ],
    [
      1515110400000,
      -8.636029305043905
    ],
    [
      1515196800000,
      -8.79468311458989
    ],
    [
      1515369600000,
      -8.979515904874994
    ],
    [
      1515456000000,
      -7.568435768120598
    ],
    [
      1515542400000,
      -6.851606059099807
    ],
    [
      1515628800000,
      -7.2287411432871
    ],
    [
      1515715200000,
      -7.58093600378163
    ],
    [
      1515801600000,
      -8.435033611219268
    ],
    [
      1515888000000,
      -12.375701279047949
    ],
    [
      1515974400000,
      -11.232895574805887
    ],
    [
      1516060800000,
      -7.2270448674662005
    ],
    [
      1516147200000,
      -9.792878636464758
    ],
    [
      1516233600000,
      -10.393986944382533
    ],
    [
      1516320000000,
      -13.549326777076999
    ],
    [
      1516406400000,
      -9.498569030433979
    ],
    [
      1516492800000,
      -9.162120084132736
    ],
    [
      1516579200000,
      -7.317862804042643
    ],
    [
      1516665600000,
      -11.04713479536914
    ],
    [
      1516752000000,
      -13.142769013852368
    ],
    [
      1516838400000,
      -9.507670801213218
    ],
    [
      1516924800000,
      -7.346581575433889
    ],
    [
      1517011200000,
      -6.300287424261126
    ],
    [
      1517097600000,
      -5.99683993445434
    ],
    [
      1517184000000,
      -8.90366842558762
    ],
    [
      1517270400000,
      -12.079836265289941
    ],
    [
      1517356800000,
      -10.76709002821734
    ],
    [
      1517529600000,
      -8.347703730990114
    ],
    [
      1517616000000,
      -5.907082440220115
    ],
    [
      1517702400000,
      -6.233606157374417
    ],
    [
      1517788800000,
      -9.203300149201787
    ],
    [
      1517875200000,
      -6.993868177654485
    ],
    [
      1517961600000,
      -6.471342795508849
    ],
    [
      1518048000000,
      -9.776672531902628
    ],
    [
      1518134400000,
      -6.2164962641238555
    ],
    [
      1518220800000,
      -9.713177489003675
    ],
    [
      1518307200000,
      -14.248949236757042
    ],
    [
      1518393600000,
      -14.900325383245962
    ],
    [
      1518480000000,
      -12.218629302128763
    ],
    [
      1518566400000,
      -8.390664662637956
    ],
    [
      1518652800000,
      -6.390428859860626
    ],
    [
      1518739200000,
      -5.930246117943843
    ],
    [
      1518825600000,
      -6.67582765040613
    ],
    [
      1518912000000,
      -8.76069999184895
    ],
    [
      1518998400000,
      -9.969713881439796
    ],
    [
      1519084800000,
      -8.97478676679777
    ],
    [
      1519171200000,
      -7.653697695617532
    ],
    [
      1519257600000,
      -9.34956784769115
    ],
    [
      1519344000000,
      -7.034114284220608
    ],
    [
      1519430400000,
      -5.676597297903304
    ],
    [
      1519516800000,
      -5.391327952664036
    ],
    [
      1519603200000,
      -5.131298147035483
    ],
    [
      1519689600000,
      -3.1080580254870305
    ],
    [
      1520035200000,
      -6.4195349768346865
    ],
    [
      1520121600000,
      -6.430678397319901
    ],
    [
      1520208000000,
      -6.241431246786039
    ],
    [
      1520294400000,
      -9.173950008606086
    ],
    [
      1520380800000,
      -9.66838168738315
    ],
    [
      1521244800000,
      -15.815253059309871
    ],
  ]
}
