





































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import SpeedLogTagHistoryChart from "./SpeedLogTagHistoryChart.vue";
import Highcharts from "highcharts";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
HighchartsNoData(Highcharts);

@Component({
  components: {
    HighCharts: Chart,
    SpeedLogTagHistoryChart,
  },
})
export default class SpeedLogTagHistory extends Vue {
@PropSync("selected", { type: String, default: "" }) selectedTimespan!: string;
@Prop({ type: Number, default: 0 }) availability!: number;
@Prop({ type: Number, default: 0 }) outliers!: number;

timeSpans = [
  "Last hour",
  "Last 4 hours",
  "Last 12 hours",
  "Last 24 hours",
  "Last 48 hours",
  "Last 3 days",
  "Last 7 days",
]
get chartOptions(): unknown {
    return {
      chart: {
        type: "spline",
        height: 200,
        backgroundColor: null,
      },
      title: {
        text: "",
      },
      legend: {
          enabled: false,
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: "Speed Log (m/s)",
        },
        minorGridLineWidth: 0,
        gridLineWidth: 1,
        alternateGridColor: null,
      },
      xAxis: {
        tickColor: null,
        labels: {
          enabled: false,
        },
        plotBands: [
          {
            from: 8,
            to: 10,
            color: "#EF9494",
          },
        ],
      },
      tooltip: {
        valueSuffix: " m/s",
      },
      series: [{
        name: "Hestavollane",
        color: "#000000",
        marker: {
          symbol: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAaNJREFUeNpiZMAB1CYkKQCpACDWB2IFqPADIL4IxBtuFcx7gE0fIw6D6oE4gQE/WADEjegGM6IZ5gCk1gOxAANx4AMQJwIN3YBhINSw/QzkgUCYoYxQw0Auuk+Cy7C51BDkfSaoQD8phsUbujJoisohCwlAw52BEeq698QaFqRlzdDumsTw6ec3hri13QzXXz9ClhZkgiYNooCZjDrYMDwggAkpjcGBi7IhAx87F4oYyItTfXLg/PZDK9BdBwIKIAPl0b0E0rgouBRuqDSfCAq/cvc8hnXXjmJzoT0TNIbgIM7ABe6iSrsIsCFTfbLhhi08vxuXYeDYBhn4EcVApIAGuXZvYic8RkEGtQG9igdcBBl4AVkEFHuVu+eDaRCAuQxkCcirBMAFWMJ+j54OQa6ChRvIMJDLYZbgSdyKsIQ9EV0W5iKQIdlbphIyDGwGMKd8QM5657ElISLBA2jWA0cKA4gByuDoMU5CPg6EmsEA8zLIUFDkOEJtI8VljlC9OAtYkPcLgDgfT4HxARruE2Auw2kglgLXAU34ANCQA7j0AAQYADiBobRmfmESAAAAAElFTkSuQmCC)",
        },
        data: [
          7.4, 5.2, 5.7, {
            y: 13.4,
            marker: {
                symbol: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAVVJREFUeNpiZMACfi6X0QRSSUDsBsQaQMwGxL+A+AYQ7wLieeyRT66j6/v//z8DI5pB/ECqH4gTgJiRATf4D8QLgLgQaPBHrAYCDZMBUnuBWI2BeHALiJ2Bhj5BMRDqslPYDGPkVfzCwMrzl+H3tz//P98VxmGoGcilIAOZoIL9uFzGbNrJw+q2jZ/FYYkwDleqQfWDARM0AhIYKAMJUHPALkwiEAHEAEaoOWAD3RioA9xgBmpQyUANmIFsVDKQDWbgLyoZCDaHBZqd9HCp+nupm+EfuyCQ8Z2QgTdgLtxFJRfughk4D5o3sQJmvVIGFtu5wATexUAgb88DGwgtNRZQ6LoFsNIHOS+fBmJVjBTLKfGdgYXrH8PfH3//f3vGh8Ww20BsCsvL6KXNPmyG4gEgw5yQSxtY4cAAFTQF4vn4whQpzOZDXfYEPQ9St8QGEdQEAAEGAG9/fxu9UWZNAAAAAElFTkSuQmCC)",
            },
            accessibility: {
                description: "Sunny symbol, this is the warmest point in the " +
                    "chart.",
            },
          }, 5.2, 5.6, 6.1,
          5.6, 5.9, null, 8.6, 7.8, 8.6,
          8.0, 9.7, 11.2, 12.5, {
            y: 14.1,
            marker: {
                symbol: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUFJREFUeNpiZMABDmjIGQApByDWB2IFIP4AxBeB+ILDjUcbcOljxGIQyJB6qGG4wAMgngg0eAJeA4GGNUANIxYcAOJAoMEfMAwEGtYPpAoYSAcXgNgRZigT1LAAXIaJ+wf/1V+w4g8Q/8ZhICis58M4TFC6H5f1HDKyzALmlixAzIrHlQHQsGdgAjISoLFIKciHudCfgTogAGagAZUMBCc5Jip5lwE9UqgGWKCpHqcrfz57yvDh1AmiDWQE+ns9LEApBcDEzQjy8kZ8iiQCQxn0F64EYwIAXGAwAU1dAPU2VsAuJc0gYGYBxgTARFgYgkAiEO/Hpurtvt3v/n7+/P3/v7+/8YT1AqDDDqAXDqSWNFgLB/TiKwE5oxMBQOGWiLX4Qiup+4koYBuh4Y+/xEYyWAFqKHq4bQAadAGXPoAAAwAKmmVHfwthPgAAAABJRU5ErkJggg==)",
            },
            accessibility: {
                description: "Sunny symbol, this is the warmest point in the " +
                    "chart.",
            },
          },
        ],
      }],
    };
  }
}
