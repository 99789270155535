export const first = [
  [
    "2023-01-07T00:00:00+00:00",
    13.686817692353857
  ],
  [
    "2023-01-08T00:00:00+00:00",
    12.66463075270446
  ],
  [
    "2023-01-10T00:00:00+00:00",
    12.130357420448538
  ],
  [
    "2023-01-15T00:00:00+00:00",
    13.251302016434614
  ],
  [
    "2023-01-16T00:00:00+00:00",
    13.050550812352391
  ],
  [
    "2023-01-17T00:00:00+00:00",
    14.435246782871952
  ],
  [
    "2023-01-18T00:00:00+00:00",
    16.983332983030504
  ],
  [
    "2023-01-19T00:00:00+00:00",
    14.298632733475293
  ],
  [
    "2023-01-20T00:00:00+00:00",
    13.663257781070381
  ],
  [
    "2023-01-21T00:00:00+00:00",
    15.035381441123027
  ],
  [
    "2023-01-22T00:00:00+00:00",
    17.153130852171067
  ],
  [
    "2023-01-23T00:00:00+00:00",
    14.402273710104708
  ],
  [
    "2023-01-24T00:00:00+00:00",
    12.613413922158779
  ],
  [
    "2023-02-26T00:00:00+00:00",
    17.75918332025412
  ],
  [
    "2023-02-27T00:00:00+00:00",
    17.745392862343685
  ],
  [
    "2023-02-28T00:00:00+00:00",
    17.772652625568263
  ],
  [
    "2023-03-01T00:00:00+00:00",
    17.329199606529727
  ],
  [
    "2023-03-02T00:00:00+00:00",
    17.898073143451708
  ],
  [
    "2023-03-03T00:00:00+00:00",
    16.600408128199323
  ],
  [
    "2023-03-04T00:00:00+00:00",
    17.25859971859232
  ],
  [
    "2023-03-05T00:00:00+00:00",
    17.825791754887025
  ],
  [
    "2023-03-06T00:00:00+00:00",
    18.115427146155977
  ],
  [
    "2023-03-07T00:00:00+00:00",
    16.766533672613196
  ],
  [
    "2023-03-08T00:00:00+00:00",
    16.17551877058145
  ],
  [
    "2023-03-09T00:00:00+00:00",
    15.8049171918303
  ],
  [
    "2023-03-10T00:00:00+00:00",
    15.654336075382899
  ],
  [
    "2023-03-11T00:00:00+00:00",
    15.85862880026245
  ],
  [
    "2023-03-12T00:00:00+00:00",
    16.16885078274594
  ],
  [
    "2023-03-13T00:00:00+00:00",
    16.261141330098397
  ],
  [
    "2023-03-14T00:00:00+00:00",
    13.894511278250326
  ],
  [
    "2023-03-15T00:00:00+00:00",
    16.459202573240734
  ],
  [
    "2023-03-20T00:00:00+00:00",
    10.139078597936644
  ],
  [
    "2023-03-21T00:00:00+00:00",
    13.471554019625486
  ],
  [
    "2023-03-22T00:00:00+00:00",
    13.134803595778148
  ],
  [
    "2022-01-17T00:00:00+00:00",
    15.73916727761402
  ],
  [
    "2022-03-15T00:00:00+00:00",
    12.93081560619579
  ],
  [
    "2022-03-16T00:00:00+00:00",
    12.767483641381334
  ],
  [
    "2022-04-15T00:00:00+00:00",
    13.562421318634886
  ],
  [
    "2022-04-16T00:00:00+00:00",
    14.830592037377828
  ],
  [
    "2022-04-17T00:00:00+00:00",
    14.95962174942659
  ],
  [
    "2022-04-18T00:00:00+00:00",
    15.85040279771499
  ],
  [
    "2022-04-19T00:00:00+00:00",
    16.027211985553635
  ],
  [
    "2022-04-20T00:00:00+00:00",
    15.923945699912885
  ],
  [
    "2022-04-21T00:00:00+00:00",
    15.146308931790896
  ],
  [
    "2022-04-22T00:00:00+00:00",
    16.072066087883044
  ],
  [
    "2022-04-23T00:00:00+00:00",
    16.089289557196942
  ],
  [
    "2022-04-24T00:00:00+00:00",
    17.241267079950944
  ],
  [
    "2022-04-25T00:00:00+00:00",
    17.429748063683192
  ],
  [
    "2022-04-26T00:00:00+00:00",
    17.92447760881239
  ],
  [
    "2022-04-27T00:00:00+00:00",
    16.619194628858246
  ],
  [
    "2022-05-23T00:00:00+00:00",
    9.155076740733588
  ],
  [
    "2022-05-24T00:00:00+00:00",
    18.013183734043423
  ],
  [
    "2022-05-25T00:00:00+00:00",
    18.44595377593454
  ],
  [
    "2022-05-26T00:00:00+00:00",
    18.040052319709446
  ],
  [
    "2022-05-27T00:00:00+00:00",
    18.50261080287229
  ],
  [
    "2022-05-28T00:00:00+00:00",
    19.237068716263646
  ],
  [
    "2022-05-29T00:00:00+00:00",
    19.13616371993279
  ],
  [
    "2022-05-30T00:00:00+00:00",
    18.870966887462306
  ],
  [
    "2022-05-31T00:00:00+00:00",
    15.621619337060784
  ],
  [
    "2022-06-02T00:00:00+00:00",
    12.6647325223999
  ],
  [
    "2022-07-07T00:00:00+00:00",
    14.827409328627262
  ],
  [
    "2022-07-08T00:00:00+00:00",
    19.838020874508565
  ],
  [
    "2022-07-09T00:00:00+00:00",
    19.716364809968677
  ],
  [
    "2022-07-10T00:00:00+00:00",
    19.165494970963508
  ],
  [
    "2022-07-11T00:00:00+00:00",
    19.34820053031156
  ],
  [
    "2022-07-12T00:00:00+00:00",
    18.51655551736707
  ],
  [
    "2022-07-13T00:00:00+00:00",
    16.66447714883211
  ],
  [
    "2022-07-14T00:00:00+00:00",
    17.047525502549977
  ],
  [
    "2022-08-12T00:00:00+00:00",
    11.74710368821339
  ],
  [
    "2022-08-13T00:00:00+00:00",
    18.418028959068742
  ],
  [
    "2022-08-14T00:00:00+00:00",
    18.044037132566448
  ],
  [
    "2022-08-15T00:00:00+00:00",
    17.905123643800472
  ],
  [
    "2022-08-16T00:00:00+00:00",
    17.82525290835473
  ],
  [
    "2022-08-17T00:00:00+00:00",
    16.549473373197205
  ],
  [
    "2022-08-18T00:00:00+00:00",
    13.439380033735338
  ],
  [
    "2022-09-05T00:00:00+00:00",
    18.851824214587744
  ],
  [
    "2022-09-06T00:00:00+00:00",
    18.543720181395418
  ],
  [
    "2022-09-07T00:00:00+00:00",
    17.407731969310827
  ],
  [
    "2022-09-30T00:00:00+00:00",
    11.979962031440381
  ],
  [
    "2022-10-01T00:00:00+00:00",
    19.100238818152707
  ],
  [
    "2022-10-02T00:00:00+00:00",
    17.819770897160883
  ],
  [
    "2022-10-03T00:00:00+00:00",
    17.25839224098835
  ],
  [
    "2022-10-04T00:00:00+00:00",
    16.553271036914747
  ],
  [
    "2022-10-05T00:00:00+00:00",
    15.483276085016682
  ],
  [
    "2022-10-06T00:00:00+00:00",
    15.985322008491428
  ],
  [
    "2022-10-08T00:00:00+00:00",
    17.363121440202505
  ],
  [
    "2022-10-09T00:00:00+00:00",
    14.086253690732752
  ],
  [
    "2022-10-10T00:00:00+00:00",
    16.457691161764505
  ],
  [
    "2022-10-11T00:00:00+00:00",
    16.626551464942928
  ],
  [
    "2022-10-12T00:00:00+00:00",
    17.085373299587836
  ],
  [
    "2022-10-13T00:00:00+00:00",
    16.94677600874583
  ],
  [
    "2022-10-14T00:00:00+00:00",
    16.977151949343703
  ],
  [
    "2022-10-15T00:00:00+00:00",
    17.211736612644707
  ],
  [
    "2022-10-16T00:00:00+00:00",
    17.802763486447432
  ],
  [
    "2022-10-17T00:00:00+00:00",
    16.293330068607506
  ],
  [
    "2022-10-18T00:00:00+00:00",
    14.916821527794587
  ],
  [
    "2022-10-19T00:00:00+00:00",
    15.14684902913141
  ],
  [
    "2022-10-20T00:00:00+00:00",
    14.071548256921764
  ],
  [
    "2022-10-21T00:00:00+00:00",
    15.641742431301685
  ],
  [
    "2022-10-22T00:00:00+00:00",
    15.610588165001387
  ],
  [
    "2022-10-23T00:00:00+00:00",
    16.01073646838564
  ],
  [
    "2022-10-24T00:00:00+00:00",
    17.06563852751331
  ],
  [
    "2022-10-25T00:00:00+00:00",
    19.150644977497123
  ],
  [
    "2022-10-26T00:00:00+00:00",
    17.84136463804222
  ],
  [
    "2022-10-27T00:00:00+00:00",
    17.6452592231019
  ],
  [
    "2022-10-28T00:00:00+00:00",
    17.25625021289971
  ],
  [
    "2022-10-29T00:00:00+00:00",
    16.698196916695714
  ],
  [
    "2022-10-30T00:00:00+00:00",
    16.87108867355378
  ],
  [
    "2022-10-31T00:00:00+00:00",
    16.696944311321197
  ],
  [
    "2022-11-01T00:00:00+00:00",
    16.828443552568373
  ],
  [
    "2022-11-02T00:00:00+00:00",
    16.949535789122116
  ],
  [
    "2022-11-03T00:00:00+00:00",
    16.99862279986181
  ],
  [
    "2022-11-04T00:00:00+00:00",
    16.619419101050013
  ],
  [
    "2022-11-05T00:00:00+00:00",
    16.727723750117317
  ],
  [
    "2022-11-06T00:00:00+00:00",
    16.898256242865987
  ],
  [
    "2022-11-07T00:00:00+00:00",
    16.943766757735073
  ],
  [
    "2022-11-08T00:00:00+00:00",
    16.675478962181423
  ],
  [
    "2022-11-09T00:00:00+00:00",
    12.071245640179294
  ],
  [
    "2022-12-05T00:00:00+00:00",
    16.62875649326937
  ],
  [
    "2022-12-06T00:00:00+00:00",
    17.001345194919384
  ],
  [
    "2022-12-07T00:00:00+00:00",
    17.19004232239266
  ],
  [
    "2022-12-08T00:00:00+00:00",
    18.698690821841147
  ],
  [
    "2022-12-09T00:00:00+00:00",
    14.109106397143755
  ],
  [
    "2022-12-10T00:00:00+00:00",
    10.786062023277758
  ],
  [
    "2022-12-11T00:00:00+00:00",
    14.081180106871633
  ],
  [
    "2022-12-12T00:00:00+00:00",
    16.381208961799633
  ],
  [
    "2022-12-13T00:00:00+00:00",
    18.621740976200805
  ],
  [
    "2022-12-14T00:00:00+00:00",
    18.901834716581497
  ],
  [
    "2022-12-15T00:00:00+00:00",
    19.097084951982993
  ],
  [
    "2022-12-16T00:00:00+00:00",
    19.442742664684545
  ],
  [
    "2022-12-17T00:00:00+00:00",
    17.21224498779055
  ],
  [
    "2021-02-11T00:00:00+00:00",
    17.009847759497703
  ],
  [
    "2021-02-12T00:00:00+00:00",
    18.269405643585994
  ],
  [
    "2021-02-13T00:00:00+00:00",
    18.18793828407418
  ],
  [
    "2021-02-14T00:00:00+00:00",
    18.02904881729344
  ],
  [
    "2021-02-17T00:00:00+00:00",
    14.81584335876015
  ],
  [
    "2021-02-18T00:00:00+00:00",
    14.622118033836884
  ],
  [
    "2021-02-19T00:00:00+00:00",
    14.024075485561434
  ],
  [
    "2021-02-21T00:00:00+00:00",
    14.298924456420243
  ],
  [
    "2021-02-22T00:00:00+00:00",
    13.489239588519409
  ],
  [
    "2021-04-01T00:00:00+00:00",
    16.680084943130765
  ],
  [
    "2021-04-06T00:00:00+00:00",
    16.74276648863406
  ],
  [
    "2021-04-07T00:00:00+00:00",
    17.789266435938597
  ],
  [
    "2021-04-08T00:00:00+00:00",
    17.389133785984093
  ],
  [
    "2021-05-13T00:00:00+00:00",
    14.272199987461738
  ],
  [
    "2021-05-14T00:00:00+00:00",
    14.394754497243346
  ],
  [
    "2021-05-15T00:00:00+00:00",
    14.951893766017166
  ],
  [
    "2021-05-16T00:00:00+00:00",
    14.427411341635318
  ],
  [
    "2021-05-17T00:00:00+00:00",
    14.53398512444167
  ],
  [
    "2021-05-18T00:00:00+00:00",
    13.496484087658235
  ],
  [
    "2021-05-19T00:00:00+00:00",
    14.890726987817292
  ],
  [
    "2021-05-23T00:00:00+00:00",
    20.290068364444842
  ],
  [
    "2021-05-24T00:00:00+00:00",
    20.251156009977976
  ],
  [
    "2021-05-25T00:00:00+00:00",
    19.92498662290528
  ],
  [
    "2021-05-26T00:00:00+00:00",
    17.132746294574993
  ],
  [
    "2021-06-14T00:00:00+00:00",
    15.298758249278189
  ],
  [
    "2021-06-15T00:00:00+00:00",
    14.422722693406342
  ],
  [
    "2021-06-16T00:00:00+00:00",
    13.958187949235803
  ],
  [
    "2021-06-17T00:00:00+00:00",
    14.705931134312399
  ],
  [
    "2021-06-18T00:00:00+00:00",
    14.78409226292549
  ],
  [
    "2021-06-20T00:00:00+00:00",
    11.954508186014168
  ],
  [
    "2021-06-21T00:00:00+00:00",
    14.838561540331572
  ],
  [
    "2021-06-22T00:00:00+00:00",
    14.660981759109879
  ],
  [
    "2021-06-23T00:00:00+00:00",
    14.926385529937571
  ],
  [
    "2021-06-24T00:00:00+00:00",
    16.43678665374306
  ],
  [
    "2021-06-25T00:00:00+00:00",
    10.93783471402292
  ],
  [
    "2021-06-26T00:00:00+00:00",
    17.15799403575663
  ],
  [
    "2021-06-27T00:00:00+00:00",
    11.870127464449421
  ],
  [
    "2021-07-26T00:00:00+00:00",
    12.542202854550842
  ],
  [
    "2021-07-27T00:00:00+00:00",
    13.45792033408641
  ],
  [
    "2021-07-28T00:00:00+00:00",
    13.44879586412225
  ],
  [
    "2021-07-29T00:00:00+00:00",
    13.388695201689815
  ],
  [
    "2021-07-30T00:00:00+00:00",
    13.018645742567719
  ],
  [
    "2021-08-05T00:00:00+00:00",
    14.619357544614498
  ],
  [
    "2021-08-28T00:00:00+00:00",
    15.200998252529537
  ],
  [
    "2021-08-30T00:00:00+00:00",
    13.420304984891033
  ],
  [
    "2021-08-31T00:00:00+00:00",
    12.212888115211644
  ],
  [
    "2021-11-18T00:00:00+00:00",
    15.056418131585358
  ],
  [
    "2021-11-25T00:00:00+00:00",
    18.36913031757241
  ],
  [
    "2021-11-26T00:00:00+00:00",
    17.755139316932954
  ],
  [
    "2021-11-27T00:00:00+00:00",
    14.30391592067629
  ],
  [
    "2021-12-18T00:00:00+00:00",
    17.282586143466947
  ],
  [
    "2021-12-19T00:00:00+00:00",
    17.292997732038767
  ],
  [
    "2021-12-20T00:00:00+00:00",
    15.790521183691652
  ],
  [
    "2021-12-21T00:00:00+00:00",
    15.786390179693598
  ],
  [
    "2021-12-22T00:00:00+00:00",
    18.44618979595552
  ],
  [
    "2021-12-23T00:00:00+00:00",
    17.740479487799213
  ],
  [
    "2021-12-24T00:00:00+00:00",
    18.755432063300674
  ],
  [
    "2020-01-18T00:00:00+00:00",
    17.063517614292298
  ],
  [
    "2020-01-19T00:00:00+00:00",
    18.259517541863705
  ],
  [
    "2020-01-20T00:00:00+00:00",
    17.765464755561414
  ],
  [
    "2020-01-21T00:00:00+00:00",
    17.481514972543795
  ],
  [
    "2020-01-22T00:00:00+00:00",
    17.057456346470197
  ],
  [
    "2020-01-23T00:00:00+00:00",
    16.8512249232299
  ],
  [
    "2020-01-24T00:00:00+00:00",
    18.12150047300968
  ],
  [
    "2020-01-25T00:00:00+00:00",
    17.83920646638263
  ],
  [
    "2020-01-26T00:00:00+00:00",
    19.16823744548273
  ],
  [
    "2020-01-27T00:00:00+00:00",
    17.441604027160718
  ],
  [
    "2020-01-28T00:00:00+00:00",
    17.105388269799946
  ],
  [
    "2020-01-29T00:00:00+00:00",
    15.357643849065058
  ],
  [
    "2020-01-30T00:00:00+00:00",
    18.021097861744096
  ],
  [
    "2020-01-31T00:00:00+00:00",
    18.041095292919106
  ],
  [
    "2020-02-01T00:00:00+00:00",
    17.48668638394383
  ],
  [
    "2020-02-02T00:00:00+00:00",
    17.90224577186053
  ],
  [
    "2020-02-03T00:00:00+00:00",
    17.898742313670414
  ],
  [
    "2020-01-12T00:00:00+00:00",
    16.09403577484337
  ],
  [
    "2020-01-14T00:00:00+00:00",
    17.09162733326402
  ],
  [
    "2020-01-13T00:00:00+00:00",
    16.949934534667886
  ],
  [
    "2020-01-15T00:00:00+00:00",
    16.89387511596348
  ],
  [
    "2020-02-04T00:00:00+00:00",
    18.66506631062335
  ],
  [
    "2020-02-20T00:00:00+00:00",
    16.301717469517182
  ],
  [
    "2020-02-21T00:00:00+00:00",
    17.340530475364286
  ],
  [
    "2020-02-22T00:00:00+00:00",
    17.5258498320303
  ],
  [
    "2020-02-23T00:00:00+00:00",
    17.696690712410604
  ],
  [
    "2020-02-24T00:00:00+00:00",
    18.616727011062235
  ],
  [
    "2020-02-25T00:00:00+00:00",
    17.604159190045216
  ],
  [
    "2020-02-26T00:00:00+00:00",
    18.388588432060352
  ],
  [
    "2020-02-27T00:00:00+00:00",
    18.21511361964243
  ],
  [
    "2020-03-13T00:00:00+00:00",
    18.157805035700513
  ],
  [
    "2020-03-14T00:00:00+00:00",
    17.58039039444163
  ],
  [
    "2020-07-14T00:00:00+00:00",
    12.824360464797943
  ],
  [
    "2020-07-15T00:00:00+00:00",
    14.505352078468466
  ],
  [
    "2020-07-16T00:00:00+00:00",
    14.78810691868233
  ],
  [
    "2020-07-17T00:00:00+00:00",
    11.946442436067732
  ],
  [
    "2020-09-25T00:00:00+00:00",
    12.965403711595402
  ],
  [
    "2020-09-26T00:00:00+00:00",
    12.970513812985827
  ],
  [
    "2020-09-28T00:00:00+00:00",
    13.775504668392646
  ],
  [
    "2020-09-29T00:00:00+00:00",
    12.814122677382658
  ],
  [
    "2020-10-20T00:00:00+00:00",
    11.624192341162734
  ],
  [
    "2020-10-21T00:00:00+00:00",
    16.061643982180396
  ],
  [
    "2020-12-05T00:00:00+00:00",
    16.956920926921295
  ],
  [
    "2020-12-06T00:00:00+00:00",
    19.004122766209953
  ],
  [
    "2020-12-08T00:00:00+00:00",
    17.45287849189386
  ],
  [
    "2020-12-09T00:00:00+00:00",
    17.633338562741105
  ],
  [
    "2020-12-10T00:00:00+00:00",
    17.693194530727716
  ],
  [
    "2020-12-11T00:00:00+00:00",
    17.6888982005545
  ],
  [
    "2020-12-12T00:00:00+00:00",
    17.71968630262942
  ],
  [
    "2020-12-29T00:00:00+00:00",
    15.821862519601057
  ],
  [
    "2020-12-30T00:00:00+00:00",
    16.914440134807784
  ],
  [
    "2019-05-11T00:00:00+00:00",
    13.295776048115453
  ],
  [
    "2019-08-13T00:00:00+00:00",
    16.470398885572713
  ],
  [
    "2019-08-14T00:00:00+00:00",
    15.522301613470741
  ],
  [
    "2019-08-15T00:00:00+00:00",
    13.818522415356062
  ],
  [
    "2019-09-07T00:00:00+00:00",
    10.664257516641447
  ],
  [
    "2019-09-20T00:00:00+00:00",
    13.023434667945686
  ],
  [
    "2019-11-09T00:00:00+00:00",
    16.28293030690071
  ],
  [
    "2019-11-10T00:00:00+00:00",
    16.44902272901253
  ],
  [
    "2019-11-15T00:00:00+00:00",
    17.528689124678422
  ],
  [
    "2019-11-16T00:00:00+00:00",
    16.9540464986029
  ],
  [
    "2019-11-17T00:00:00+00:00",
    17.746152089656793
  ],
  [
    "2019-11-18T00:00:00+00:00",
    17.981294059501916
  ],
  [
    "2019-11-20T00:00:00+00:00",
    16.08084383270573
  ],
  [
    "2019-11-19T00:00:00+00:00",
    17.88066241222121
  ],
  [
    "2019-11-22T00:00:00+00:00",
    16.374102403014916
  ],
  [
    "2019-11-21T00:00:00+00:00",
    14.562276239744532
  ],
  [
    "2019-11-23T00:00:00+00:00",
    17.197607973589484
  ],
  [
    "2019-11-24T00:00:00+00:00",
    17.1653054911108
  ],
  [
    "2019-11-25T00:00:00+00:00",
    15.20019897318214
  ],
  [
    "2019-11-26T00:00:00+00:00",
    14.50582460581321
  ],
  [
    "2019-11-28T00:00:00+00:00",
    16.993952408072794
  ],
  [
    "2019-11-27T00:00:00+00:00",
    17.566070815985494
  ],
  [
    "2019-11-29T00:00:00+00:00",
    16.028954497912913
  ],
  [
    "2019-11-30T00:00:00+00:00",
    15.463847729065126
  ],
  [
    "2019-12-01T00:00:00+00:00",
    15.353254601770884
  ]
]

export const second = [
  [
    "2024-01-11T00:00:00+00:00",
    8.321588535548548
  ],
  [
    "2024-01-12T00:00:00+00:00",
    15.802192224469724
  ],
  [
    "2024-01-13T00:00:00+00:00",
    16.81738068029461
  ],
  [
    "2024-01-14T00:00:00+00:00",
    16.534047500849333
  ],
  [
    "2024-01-15T00:00:00+00:00",
    15.562515449551773
  ],
  [
    "2024-01-16T00:00:00+00:00",
    12.105601456189529
  ],
  [
    "2024-02-06T00:00:00+00:00",
    18.00861913657266
  ],
  [
    "2024-02-07T00:00:00+00:00",
    17.244871902741327
  ],
  [
    "2024-02-08T00:00:00+00:00",
    17.256559896243463
  ],
  [
    "2024-02-09T00:00:00+00:00",
    16.925282372585897
  ],
  [
    "2024-02-10T00:00:00+00:00",
    16.074721261807596
  ],
  [
    "2024-02-11T00:00:00+00:00",
    17.12895196874886
  ],
  [
    "2024-02-12T00:00:00+00:00",
    17.60403076128148
  ],
  [
    "2024-02-13T00:00:00+00:00",
    17.27795810886444
  ],
  [
    "2024-02-14T00:00:00+00:00",
    18.17289039018866
  ],
  [
    "2024-02-15T00:00:00+00:00",
    16.432117348315668
  ],
  [
    "2024-02-16T00:00:00+00:00",
    13.518340117709846
  ],
  [
    "2024-02-17T00:00:00+00:00",
    17.37139196430733
  ],
  [
    "2024-02-18T00:00:00+00:00",
    18.871008503645637
  ],
  [
    "2024-02-19T00:00:00+00:00",
    18.684723826340765
  ],
  [
    "2024-02-20T00:00:00+00:00",
    18.71980578748612
  ],
  [
    "2024-02-21T00:00:00+00:00",
    17.16044355216067
  ],
  [
    "2024-02-22T00:00:00+00:00",
    10.618369959994304
  ],
  [
    "2024-05-29T00:00:00+00:00",
    15.726839320571047
  ],
  [
    "2024-06-03T00:00:00+00:00",
    17.662428625134662
  ],
  [
    "2024-06-04T00:00:00+00:00",
    17.67025489865937
  ],
  [
    "2024-06-05T00:00:00+00:00",
    17.4641185151536
  ],
  [
    "2024-06-06T00:00:00+00:00",
    17.407986951176333
  ],
  [
    "2024-06-07T00:00:00+00:00",
    16.86670174895534
  ],
  [
    "2024-06-08T00:00:00+00:00",
    16.477991605091862
  ],
  [
    "2024-06-09T00:00:00+00:00",
    16.719774265979044
  ],
  [
    "2024-06-10T00:00:00+00:00",
    17.619128398325184
  ],
  [
    "2024-06-11T00:00:00+00:00",
    17.759345155602862
  ],
  [
    "2024-06-12T00:00:00+00:00",
    17.646215347382302
  ],
  [
    "2024-06-13T00:00:00+00:00",
    16.607507491119524
  ],
  [
    "2024-06-14T00:00:00+00:00",
    16.234650844415114
  ],
  [
    "2024-06-15T00:00:00+00:00",
    18.53162868613364
  ],
  [
    "2024-06-16T00:00:00+00:00",
    19.19313925046177
  ],
  [
    "2024-06-17T00:00:00+00:00",
    15.220082161132527
  ]
]
