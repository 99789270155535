









































import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
// utilities
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import dateHelper from "@/Scripts/utilities/date-helper";

//  types
import { IDataSerie } from "@/types/highcharts/dataSerie";
import data from "./BiasFakeData";

@Component({
  components: {
    Highcharts: Chart,
  },
})
export default class SensorQualityChart extends Vue {
  chart!: any;
  chartLoaded = false;
  fakeData: any = data;

  //  @Getters
  get ChartOptions(): any {
    if (!this.chartLoaded || !Highcharts) return {};
    const ctx = this;
    const options = {
      chart: ctx.chartSettings,
      title: { text: "" },
      legend: {
        enabled: false,
      },
      yAxis: {
        min: -20,
        max: 20,
        title: {
          text: "",
        },
        labels: {
          format: "{value} %",
          style: { color: "#331714" },
        },
      },
      xAxis: {
        title: {
          text: null,
        },
        type: "datetime",
        minRange: 1,
        labels: {
          y: 35,
        },
        minorTickLength: 0,
        tickLength: 0,
      },
      plotOptions: {
        area: {
          threshold: 0,
          marker: {
            enabled: false,
          },
        },
        series: {
          showInLegend: true,
          dataLabels: { enabled: false },
        },
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#000",
        borderWidth: 0,
        borderRadius: 13,
        shadow: false,
        style: {
          color: "#fff",
          fontSize: 14,
        },
        formatter: function () {
          const $this: any = this;
          return `
            <div style="line-height: 18px;">
                <span style="margin-bottom: 5px; font-size: 12px;">${dateHelper.getFormatedDateString($this.point.x)}</span> <br />
                <span style="font-size: 12px;">${Number($this.point.y.toFixed(2))}%</span>
            </div>
            `;
        },
      },
      series: this.seriesData,
      credits: { enabled: false },
      exporting: { enabled: false },
    };

    return options;
  }

  get chartSettings(): any {
    if (!this.chartLoaded) return {};
    const ctx = this;
    return {
      type: "line",
      zoomType: "x",
      spacingRight: 0,
      spacingLeft: 0,
      spacingTop: 28,
      // animation: false,
      height: 315,
      style: { fontFamily: "Helvetica Neue" },
      events: {
        selection: function (event: any) {
          //  trigger onChartZoom event on zoom
          ctx.$emit("onEventChartZoom", event);
        },
      },
    };
  }

  get seriesData(): IDataSerie[] {
    if (!this.fakeData) return [] as IDataSerie[];
    const baseline = {
      name: "Baseline",
      type: "line",
      dashStyle: "dash",
      color: "#CFC9C4",
      enableMouseTracking: false,
      lineWidth: 1,
      cropThreshold: 9999,
      marker: { enabled: false },
      zIndex: 4,
      data: [
        [1455494400000, 0],
        [1521244800000, 0],
      ],
    };
    let series: IDataSerie[] = [];

    series = [this.fakeData, baseline];

    return series;
  }

  //  @Methods
  chartReady(chart: any): void {
    this.chart = chart;
    this.chart.update(this.ChartOptions, true);
    this.chartLoaded = true;
  }
}
