





















  import { Vue, Component, Prop } from "vue-property-decorator";

@Component
  export default class SpeedLogAvailabilityStatus extends Vue {
  @Prop({ type: Boolean, default: false }) isSensorAvailable!: boolean;
  @Prop({ type: Boolean, default: false }) isVesselSelected!: boolean;
  }
