



































import { Vue, Component, Prop } from "vue-property-decorator";

interface StatusOptions {
  type: string;
  status: number;
  tooltip?: string;
}

@Component({})
export default class StatusCard extends Vue {
  @Prop() options!: StatusOptions;

  //  @Getters

  get indicatorTitle(): string {
    if (this.options.type === "DataAvailability") {
      return "Data Availability Status";
    } else if (this.options.type === "DataQuality") {
      return "Sensor Quality";
    } else {
      return "N/A";
    }
  }

  get indicatorText(): string {
    if (this.options.type === "DataAvailability") {
      switch (this.options.status) {
        case 1:
          return "Available";
        case 2:
          return "Observe";
        case 3:
          return "Critical";
        default:
          return "N/A";
      }
    } else if (this.options.type === "DataQuality") {
      switch (this.options.status) {
        case 1:
          return "Good Quality";
        case 2:
          return "Degrading Quality";
        case 3:
          return "Poop Quality";
        default:
          return "N/A";
      }
    } else {
      return "N/A";
    }
  }

  get indicatorIcon(): string {
    switch (this.options.status) {
      case 1:
        return "mdi-check-circle";
      case 2:
        return "mdi-alert-circle";
      case 3:
        return "mdi-minus-circle";
      default:
        return "mdi-close-circle";
    }
  }
  get statusColor() {
    if (!this.options) return;
    switch (this.options.status) {
      case 1:
        return "success100";
      case 2:
        return "warning100";
      case 3:
        return "error100";
      default:
        return "darkGrey100";
    }
  }
}
