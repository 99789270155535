








import { Component, Prop, Vue } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
HighchartsNoData(Highcharts);

@Component({
  components: {
    HighCharts: Chart,
  },
})
export default class SpeedLogTagHistoryChart extends Vue {
  @Prop({ type: Object, required: true }) chartOptions!: Highcharts.Options;
}
